import React, {FunctionComponent, CSSProperties} from 'react';
import picture from 'src/images/portraits/olouvignes_2.jpg';

export type Props = {
  style?: CSSProperties;
  className?: string;
  onCloseArticle: () => void;
};

const AboutPage: FunctionComponent<Props> = ({style, className, onCloseArticle}) => (
  <article id="about" style={style} className={className}>
    <h2 className="major">À Propos</h2>
    <span className="image main" />
    <p>
      <a href="https://www.linkedin.com/in/olouvignes/" target="_blank">
        <img
          src={picture}
          alt="Olivier Louvignes"
          height="160"
          style={{float: 'left', marginRight: '16px', marginBottom: '16px'}}
        />
      </a>
      Fondée en 2009 par{' '}
      <a href="https://www.linkedin.com/in/olouvignes/" target="_blank">
        Olivier Louvignes
      </a>
      , Magenta Creations est une agence digitale spécialisée dans la conception d'experiences digitales innovantes.
      <br />
    </p>
    <br />
    <br />
    <br />
    <br />
    <h4>Adresse du siège</h4>
    <ul>
      <li>8 Rue des Anciennes Granges</li>
      <li>78860, Saint Nom La Bretèche</li>
    </ul>
    <a href="https://www.google.fr/maps/dir//8+Rue+des+Anciennes+Granges,+78860+Saint-Nom-la-Bretèche/" target="_blank">
      <img
        width="100%"
        style={{marginBottom: '16px'}}
        src="https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/2.0218,48.8592,12.2,0/480x160@2x?access_token=pk.eyJ1IjoibWdjcmVhIiwiYSI6ImNrZDNtdXI2eTBoaWEyeW55MGRrNGJodXMifQ.Z2asL9nKlwoLsd9tqeY85w"
        alt="Google Map of 8 Rue des Anciennes Granges, 78860 Saint-Nom-la-Bretèche"
      />
    </a>
    <div className="close" onClick={onCloseArticle} />
  </article>
);

export default AboutPage;

/*
      , Magenta Creations is a digital agency focused on building innovative digital experiences.
      <br />
      <br />
      With a team of well-rounded engineers, we can deliver anything from native mobile applications, public websites or
      pure backends.
      */
