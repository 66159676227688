import React, {FunctionComponent, CSSProperties} from 'react';

export type Props = {
  style?: CSSProperties;
};

const Footer: FunctionComponent<Props> = ({style}) => (
  <footer id="footer" style={style}>
    <p className="copyright">
      &copy; Magenta Creations. Built with <span className="icon fa-heart" /> in{' '}
      <a href="https://fr.wikipedia.org/wiki/Paris" target="_blank">
        Paris
      </a>
      . Powered by:{' '}
      <a href="https://www.gatsbyjs.org/" target="_blank">
        Gatsby
      </a>
    </p>
  </footer>
);

export default Footer;
