import React, {
  FunctionComponent,
  CSSProperties,
  useState,
  useCallback,
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef
} from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import {HCAPTCHA_SITE_KEY} from 'src/config/env';

const {grecaptcha} = typeof window !== 'undefined' ? window : global;

export type Props = {
  style?: CSSProperties;
  className?: string;
  onCloseArticle: () => void;
};

const ContactPage: FunctionComponent<Props> = ({style, className, onCloseArticle}) => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [wasSent, setWasSent] = useState<boolean>(false);
  const [didRecaptcha, setDidRecaptcha] = useState<boolean>(false);

  // const recaptchaId = useRef<number | null>(null);
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (!grecaptcha) {
  //       return;
  //     }
  //     recaptchaId.current = grecaptcha.render('recaptcha', {
  //       sitekey: '6Lf8kqcUAAAAAA7mGXlWrdvxxMe6mcrJbivHKdmF',
  //       theme: 'dark',
  //       callback: () => {
  //         setDidRecaptcha(true);
  //       }
  //     });
  //   }, 100);
  // }, []);

  const handleVerificationSuccess = useCallback(token => {
    console.warn('handleVerificationSuccess', {token});
  }, []);

  const handleInputChange = (callback: Dispatch<SetStateAction<string>>) =>
    useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {value} = event.target;
      callback(value);
    }, []);

  const handleFormSubmit = useCallback(
    async event => {
      event.preventDefault();
      /*
      const {method, action}: {method: string; action: string} = event.target;
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      };
      const recaptcha = grecaptcha.getResponse(recaptchaId.current);
      const body = JSON.stringify({name, email, message, recaptcha});
      setIsLoading(true);
      try {
        const response = await fetch(action, {method, headers, body});
        const content = await response.json();
        if (content.ok === 1) {
          setWasSent(true);
        }
      } finally {
        setIsLoading(false);
      }
      */
    },
    [name, email, message]
  );

  return (
    <article id="contact" style={style} className={className}>
      <h2 className="major">Contact</h2>
      <form method="post" action="/api/mail" onSubmit={handleFormSubmit}>
        <div className="field half first">
          <label htmlFor="name">Nom</label>
          <input type="text" name="name" value={name} onChange={handleInputChange(setName)} required />
        </div>
        <div className="field half">
          <label htmlFor="email">Email</label>
          <input type="email" name="email" value={email} onChange={handleInputChange(setEmail)} required />
        </div>
        <div className="field">
          <label htmlFor="message">Message</label>
          <textarea name="message" rows={4} value={message} onChange={handleInputChange(setMessage)} required />
        </div>
        <HCaptcha sitekey={HCAPTCHA_SITE_KEY} onVerify={handleVerificationSuccess} />
        <ul className="actions">
          <li>
            <input
              type="submit"
              disabled={!didRecaptcha || isLoading || wasSent}
              value={wasSent ? 'Merci!' : 'Envoyer Message'}
              className={wasSent ? 'special' : ''}
            />
          </li>
          <li>
            <input type="reset" value="Effacer" />
          </li>
          {isLoading ? (
            <li>
              <span className="icon fa-refresh fa-spin" />
            </li>
          ) : null}
        </ul>
      </form>
      <ul className="icons">
        <li>
          <a href="https://www.linkedin.com/in/olouvignes/" className="icon fa-linkedin" target="_blank">
            <span className="label">Linkedin</span>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/olouv/" className="icon fa-twitter" target="_blank">
            <span className="label">Twitter</span>
          </a>
        </li>
        {/* <li>
        <a href="#" className="icon fa-facebook">
          <span className="label">Facebook</span>
        </a>
      </li>
      <li>
        <a href="#" className="icon fa-instagram">
          <span className="label">Instagram</span>
        </a>
      </li> */}
        <li>
          <a href="https://github.com/mgcrea/" className="icon fa-github" target="_blank">
            <span className="label">GitHub</span>
          </a>
        </li>
      </ul>
      <div className="close" onClick={onCloseArticle} />
    </article>
  );
};

export default ContactPage;
